<template>
  <div class="home-page fill-height">

    <v-row class="fill-height" style="margin-top: 0 !important;">
      <left-side-image/>

      <v-col cols="12" md="7" >

        <v-row align="center" justify="center">
          <v-col cols="12" md="8">
            <div class="mt-12 primary--text">
              <h1 class="text-center today-regular home-title">Financia tu seguro</h1>

            </div>
          </v-col>
          <v-col cols="12" md="7">
            <h2 class="text-center primary--text today-light" style="font-weight: 100; line-height: 1;">En VARDI encontrarás la financiación para la póliza que necesitas</h2>
          </v-col>
        </v-row>

        <v-col class="d-flex align-center">
          <v-container>

            <v-row justify="center" class="mt-6">
              <v-col cols="12" md="4" lg="4">

                <v-card
                    class="mx-auto pt-4"
                    max-width="320"
                    height="210"
                    hover
                    outlined
                    style="border-color: #00205B !important; border-radius: 12px;"
                    :to="{ name: 'SimulateFinancing'}"
                    v-on:mouseenter="mouseEnter(1)"
                    v-on:mouseleave="mouseLeave(1)"
                    :color="simulateColor"
                    >
                  <v-img max-height="140"
                         class="mb-4 home-image"
                         contain
                         :src="simulateImg"
                  ></v-img>

                  <v-card-title class="today-medium text-center justify-center px-10 card-subtitle"
                  :class="simulateColorText">
                    Simular Financiación
                  </v-card-title>
                </v-card>

              </v-col>
              <v-col cols="12" md="4" lg="4">
                <v-card
                    class="mx-auto pt-4"
                    max-width="320"
                    height="210"
                    hover
                    outlined
                    style="border-color: #00205B !important; border-radius: 12px;"
                    @click.stop="dialog = true"
                    v-on:mouseenter="mouseEnter(2)"
                    v-on:mouseleave="mouseLeave(2)"
                    :color="financeColor">
                  <v-img max-height="140"
                         class="mb-4 home-image"
                         contain
                         :src="financeImg"
                  ></v-img>

                  <v-card-title class="today-medium text-center justify-center px-10 card-subtitle"
                  :class="financeColorText">
                    Financiar Póliza
                  </v-card-title>

                </v-card>
              </v-col>
              <v-col cols="12" md="4" lg="4">
                <v-card
                    class="mx-auto pt-4"
                    max-width="320"
                    height="210"
                    hover
                    outlined
                    style="border-color: #00205B !important; border-radius: 12px;"
                    :href="'https://tefio-vardi.bio.credit/'"
                    :target="'_blank'"
                    v-on:mouseenter="mouseEnter(3)"
                    v-on:mouseleave="mouseLeave(3)"
                    :color="paymentColor">
                  <v-img max-height="140"
                         class="mb-4 home-image"
                         contain
                         :src="paymentImg"
                  ></v-img>

                  <v-card-title class="today-medium text-center justify-center px-10 card-subtitle"
                  :class="paymentColorText">
                    Pagar Cuota
                  </v-card-title>

                </v-card>
              </v-col>
            </v-row>

          </v-container>

        </v-col>



        <v-footer
            padless
            inset
            light
            class="mt-16">
          <v-col md="12" class="today-medium text-center white" style="color: #e0e0e0;" >
            Copyright {{ new Date().getFullYear() }} VARDI servicios financieros. All Rights Reserved. Desarrollado por <a href="https://bio.credit/" style="color: #e0e0e0; text-decoration: none;" target="_blank">BioCredit</a>
          </v-col>
        </v-footer>


      </v-col>


    </v-row>

    <v-dialog
        v-model="dialog"
        max-width="400"
        overlay-color="primary"
    >
      <v-card>
        <v-card-title class="justify-center text-uppercase primary--text font-weight-bold today-medium">
          Ingresar información
        </v-card-title>

        <div class="px-14 pb-6 mt-4">
          <v-form
              ref="form"
              lazy-validation
              @submit.prevent="singUp">

            <v-select
                v-model="document_type"
                :items="document_types"
                item-text='value'
                item-value='key'
                :rules="[v => !!v || 'Selecciona el campo']"
                label="Tipo de documento"
                required
                outlined
                dense
                color="primary"
                class="select-field"
            ></v-select>

            <v-text-field
                v-model="document_id"
                :rules="[v => !!v || 'Ingresa la información']"
                type="number"
                label="Número de documento"
                required
                outlined
                dense
                color="primary"
                class="input-field"
            ></v-text-field>

            <v-icon color="primary">mdi-ticket-percent-outline</v-icon> <a href="#" @click="promoCode = !promoCode" class="today-bold" style="font-size: 0.65rem !important;"  color="primary">Si tienes un código promocional ingrésalo aquí</a>

            <v-text-field v-if="promoCode"
                          v-model="coupon"
                          label="Ingresa el código para redimirlo"
                          class="input-field"
            ></v-text-field>

            <a href="https://vardiseguros.com.co/static/uploads/613b959d-eb39-4cad-b1e4-b9a9344194af.pdf" target="_blank" class="terms-link mt-3 today-bold" style="font-size: 0.65rem !important;" color="primary">Al ingresar estas aceptando los términos y condiciones, políticas de privacidad y tratamiento de datos personales</a>

            <v-layout justify-center pt-4>
              <v-card-actions>
                <v-btn
                    :loading="loading"
                    :disabled="loading"
                    color="primary"
                    type="submit"
                    class="px-12 text-capitalize vardi-button">
                  Continuar
                </v-btn>
              </v-card-actions>
            </v-layout>

          </v-form>
        </div>

      </v-card>
    </v-dialog>


    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        bottom
        right>
      <div v-html="snackbarMessage"></div>
    </v-snackbar>
  </div>

</template>

<script>
import OnboardingModal from "@/components/Layouts/OnboardingModal";
import LeftSideImage from "@/components/Layouts/LeftSideImage";
import {api} from "@/helpers/axios";
import {constants} from "@/helpers/constants";
import {locals} from "@/helpers/locals";


export default {
  name: 'home-app',
  components: {OnboardingModal, LeftSideImage},
  data() {
    return {
      document_type: '',
      document_id: '',
      coupon: '',
      dialog: false,
      promoCode: false,
      loading: false,
      settings: null,
      document_types: [],
      snackbar: false,
      timeout: 3000,
      snackbarMessage: '',
      user: null,
      cardClass: '',
      simulateColor: 'white',
      simulateImg: 'https://app-biocredit.s3.amazonaws.com/vardi/home/presupuesto.png',
      simulateColorText: 'primary--text',
      financeColor: 'white',
      financeImg: 'https://app-biocredit.s3.amazonaws.com/vardi/home/salario.png',
      financeColorText: 'primary--text',
      paymentColor: 'white',
      paymentImg: 'https://app-biocredit.s3.amazonaws.com/vardi/home/tarjeta-de-credito.png',
      paymentColorText: 'primary--text',

      windowHeight: window.innerWidth

    }
  },
  methods: {
    mouseEnter(item) {

      switch (item) {
        case 1:
          this.simulateColor = '#00205B';
          this.simulateImg = 'https://app-biocredit.s3.amazonaws.com/vardi/home/presupuesto-blanco.png';
          this.simulateColorText = 'white--text';
          break;
        case 2:
          this.financeColor = '#00205B';
          this.financeImg = 'https://app-biocredit.s3.amazonaws.com/vardi/home/salario-blanco.png';
          this.financeColorText = 'white--text';
          break;
        case 3:
          this.paymentColor = '#00205B';
          this.paymentImg = 'https://app-biocredit.s3.amazonaws.com/vardi/home/tarjeta-de-credito-blanco.png';
          this.paymentColorText = 'white--text';
          break;
      }

      console.log('mouseEnter');
    },
    mouseLeave(item) {

      switch (item) {
        case 1:
          this.simulateColor = 'white';
          this.simulateImg = 'https://app-biocredit.s3.amazonaws.com/vardi/home/presupuesto.png'
          this.simulateColorText = 'primary--text';
          break;
        case 2:
          this.financeColor = 'white';
          this.financeImg = 'https://app-biocredit.s3.amazonaws.com/vardi/home/salario.png'
          this.financeColorText = 'primary--text';
          break;
        case 3:
          this.paymentColor = 'white';
          this.paymentImg = 'https://app-biocredit.s3.amazonaws.com/vardi/home/tarjeta-de-credito.png'
          this.paymentColorText = 'primary--text';
          break;
      }



    },
    singUp() {
      let validate = this.$refs.form.validate();
      if(validate) {
        this.loading = true;

        api.post(constants.endPoints.isRegistered, {
          document_type: this.document_type,
          document_id: this.document_id,
          coupon: this.coupon
        }).then(function (response) {
              this.loading = false;
              let exist_user = response.data.data.exist_user;
              let exist_sics = response.data.data.exist_sics;

              let user = {
                document_type: this.document_type,
                document_id: this.document_id,
                coupon: this.coupon
              };

              locals.setLocal('user', JSON.stringify(user));

              if(exist_user) {
                user.login = true;
                locals.setLocal('user', JSON.stringify(user));

                this.$router.push('/preparate-para-selfie');
              } else {
                if (exist_sics.status) {
                  this.$router.push('/registro-de-documento');
                } else {
                  this.snackbarMessage = exist_sics.message;
                  this.snackbar = true;
                }

              }
            }.bind(this)).catch(function (error) {
              let errors = error.response.data.errors;
              let message = '';
              for (var [key, value] of Object.entries(errors)) {
                message = message + value + '<br>';
              }
              this.snackbarMessage = message;
              this.snackbar = true;
              this.loading = false;

            }.bind(this))
      }

    },

    onResize() {
      this.windowHeight = window.innerWidth
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },

  created() {
    api.get(constants.endPoints.settings)
        .then(response => {
          locals.setLocal('settings', JSON.stringify(response.data.data));
          this.settings = locals.getLocal('settings');
          this.settings.config.forEach((value) => {
            if(value.key == 'document_types') {
              this.document_types = value.value;
            }
          });
        })

    this.user = locals.getLocal('user');
    if(this.user != null) {
      if(this.user.login && this.user.access_token) {
        this.$router.push('/polizas')
      }
    }

  }
}
</script>

<style scoped>
.v-application .text-caption {
  letter-spacing: 0 !important;
  line-height: 0.8rem !important;
  font-size: 0.65rem !important;
}

.terms-link {
  display: block;
}

.v-card__title {
  word-break: inherit;
}

.home-title {
  font-size: 2.5em;
  line-height: 1;
}

.card-subtitle {
  font-size: 22px;
  line-height: 1;
}

.home-image {
  height: 100px;
}

@media (max-width: 600px) {
  .home-title {
    font-size: 2em;
    line-height: 1;
  }

  .card-subtitle {
    font-size: 18px;
    line-height: 1;
  }

  .home-image {
    height: 140px;
  }
}
</style>
