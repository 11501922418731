export const locals = {
    getLocal,
    setLocal,
    clearLocal
};

function getLocal(item) {
    let localVariable = JSON.parse(localStorage.getItem(item));
    return localVariable;
}

function setLocal(item, data) {
    localStorage.setItem(item, data);
}

function clearLocal() {
    localStorage.clear();
}

